export const selectNewUser = state => state.auth.newUser;

export const selectCurrentUser = state => state.auth.currentUser;

export const selectUsersData = state => state.auth.usersData;

export const selectAdminUser = state => state.auth.currentUser.admin;

export const selectDemoUser = state => state.auth.currentUser.demo;

export const selectIsLoggedIn = state => state.auth.isLoggedIn;

export const selectIsRefreshing = state => state.auth.isRefreshing;

export const selectUserFetching = state => state.auth.userFetching;
