import { register, login, refreshUser, getUsersData } from './authOperation';
const { createSlice } = require('@reduxjs/toolkit');

const initialState = {
  newUser: { username: null, admin: null },
  currentUser: { username: null, admin: false, demo: false },
  token: null,
  isLoggedIn: false,
  error: null,
  isRefreshing: true,
  userFetching: false,
  usersData: [],
};

const pending = state => {
  state.isRefreshing = true;
  state.userFetching = true;
};
const rejected = state => {
  state.isRefreshing = false;
  state.userFetching = false;
  state.isLoggedIn = false;
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  extraReducers: builder =>
    builder
      .addCase(register.rejected, (state, { payload }) => {
        state.userFetching = false;
        state.isRefreshing = false;
        state.error = payload;
      })
      .addCase(register.pending, pending)
      .addCase(register.fulfilled, (state, { payload }) => {
        state.newUser.username = payload.user.username;
        state.newUser.admin = payload.user.admin;
        state.userFetching = false;
        state.isRefreshing = false;
      })
      .addCase(login.fulfilled, (state, { payload }) => {
        state.currentUser.username = payload.user.username;
        state.currentUser.admin = payload.user.admin;
        state.currentUser.demo = payload.user.demo;

        state.token = payload.token;
        state.isLoggedIn = true;
        state.userFetching = false;
        state.isRefreshing = false;
        state.error = null;
      })

      .addCase(login.rejected, (state, { payload }) => {
        state.isLoggedIn = false;
        state.userFetching = false;
        state.isRefreshing = false;
        state.error = payload;
      })
      .addCase(login.pending, pending)
      .addCase(refreshUser.pending, (state, { payload }) => {
        state.isRefreshing = true;
        state.userFetching = true;
      })
      .addCase(refreshUser.fulfilled, (state, { payload }) => {
        state.currentUser.username = payload.username;
        state.currentUser.admin = payload.admin;
        state.currentUser.demo = payload.demo;

        state.userFetching = false;
        state.isLoggedIn = true;
        state.isRefreshing = false;
        state.error = null;
      })

      .addCase(refreshUser.rejected, rejected)

      .addCase(getUsersData.rejected, (state, { payload }) => {
        state.userFetching = false;
        state.isRefreshing = false;
        state.error = payload;
      })
      .addCase(getUsersData.pending, pending)
      .addCase(getUsersData.fulfilled, (state, { payload }) => {
        state.usersData = payload.data;

        state.userFetching = false;
        state.isRefreshing = false;
      }),
});

export default authSlice.reducer;
