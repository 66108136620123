import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { selectDemoUser, selectIsLoggedIn } from 'redux/selectors';

export const PublicRoute = ({
  component: Component,
  redirectTo = '/',
  restricted = false,
}) => {
  const isDemo = useSelector(selectDemoUser);
  const isLoggedIn = useSelector(selectIsLoggedIn);

  const shoudlRedirect = isLoggedIn && restricted;
  return (
    <>
      {shoudlRedirect ? (
        <Navigate to={isDemo ? '/study/demo' : redirectTo} />
      ) : (
        <Component />
      )}
    </>
  );
};
