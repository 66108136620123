import { Navigate, Route, Routes } from 'react-router-dom';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { lazy, Suspense, useEffect } from 'react';
import { accordionAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { refreshUser } from 'redux/auth/authOperation';
import { PublicRoute } from 'service/PublicRoute/PublicRoute';
import { PrivateRoute } from 'service/PrivateRoute/PrivateRoute';

const Login = lazy(() => import('../page/Login/Login'));
const DemoPage = lazy(() => import('../page/DemoPage/DemoPage'));
const LoginDemo = lazy(() => import('../page/Login/LoginDemo'));
const TaPage = lazy(() => import('../page/TaPage/TaPage'));
const Main = lazy(() => import('../page/Main/Main'));
const Dashboard = lazy(() => import('../page/Dashboard/Dashboard'));
const Tutorial = lazy(() => import('../page/Tutorial/Tutorial'));
const Fund = lazy(() => import('../page/Fund/Fund'));

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(accordionAnatomy.keys);

const baseStyle = definePartsStyle({
  panel: {
    cursor: 'pointer',
    textTransform: 'lowerCase',
    fontWeight: '300',
    _hover: {
      color: '#ff6e30',
    },
  },
});

export const accordionTheme = defineMultiStyleConfig({ baseStyle });

const theme = extendTheme({
  colors: {
    art: '#ff6e30',
  },
  components: {
    Accordion: accordionTheme,

    Text: {
      baseStyle: {
        mb: '20px',
        listStylePosition: 'inside',
        '@media screen and (max-width: 768px)': {},
      },
    },
  },
  fonts: {
    heading: `'Helvetica-Neue', sans-serif`,
    body: `'Helvetica-Neue', sans-serif`,
  },
  styles: {
    global: {
      fontSize: '20px',
      '@media screen and (min-width: 2500px)': {
        fontSize: '25px',
      },
    },
  },
});

export const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(refreshUser());

    const handleContextMenu = e => {
      e.preventDefault();
    };
    document.addEventListener('dblclick', handleContextMenu);
    document.addEventListener('contextmenu', handleContextMenu);
    return () => {
      document.removeEventListener('dblclick', handleContextMenu);
      document.removeEventListener('contextmenu', handleContextMenu);
    };
  }, [dispatch]);

  return (
    <>
      <Suspense fallback={<div>Loading...</div>}>
        <ChakraProvider theme={theme}>
          <Routes>
            <Route path="/" element={<Fund />} />
            <Route exact path="/" element={<Main />}></Route>
            

            <Route
              path="/study/login"
              element={
                <PublicRoute
                  component={Login}
                  redirectTo="/study/base"
                  restricted
                />
              }
            />
            <Route
              path="/study/demo-login"
              element={
                <PublicRoute
                  component={LoginDemo}
                  redirectTo="/study/demo"
                  restricted
                />
              }
            />
            <Route
              path="/study/dashboard"
              element={
                <PrivateRoute component={Dashboard} redirectTo="/study/login" />
              }
            ></Route>
            <Route
              path="/study/base"
              element={
                <PrivateRoute component={TaPage} redirectTo="/study/login" />
              }
            ></Route>
            <Route
              path="/study/tutorial"
              element={
                <PrivateRoute component={Tutorial} redirectTo="/study/base" />
              }
            ></Route>
            <Route
              path="/study/demo"
              element={
                <PrivateRoute
                  component={DemoPage}
                  redirectTo="/study/demo-login"
                />
              }
            ></Route>
            <Route path="*" element={<Navigate to="/study/login" />} />
          </Routes>
        </ChakraProvider>
      </Suspense>
    </>
  );
};
